import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { ProductType } from '@shared/gql/document-nodes'
import { useRef } from 'react'
import { useProductAvailabilities } from 'services/Product/hooks/use-product-availabilities'
import { getProductAvailabilityForDate } from 'services/Product/utils/get-product-availability-for-date'
import styled from 'styled-components'
import { sortAlcoholOnTop } from 'utils/cart/filter-alcohol-items'
import { sortMissingOnTop } from 'utils/cart/filter-missing-items'
import { useGetClientCartItemsByType } from 'utils/cart/use-get-client-cart-items-by-type'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { TrackingContextProvider } from 'utils/tracking/components/TrackingContextProvider'
import { TrackingContext } from 'utils/tracking/interfaces/data-layer-events'
import { CartAlertsContainer } from 'views/shopping-cart/containers/CartAlertsContainer'
import { CartHeaderContainer } from 'views/shopping-cart/containers/CartHeaderContainer'
import { CreateListFromCart } from 'views/shoppinglists/CreateListFromCart'

import { CartOrderHistory } from './cart-order-history/CartOrderHistory'
import { CartIsEmpty } from './CartIsEmpty'
import { CartItem } from './CartItem'

interface ShoppingCartProps {
  className?: string
}

const { featureFlags } = getConfig()

const _ShoppingCart: FC<ShoppingCartProps> = ({ className }) => {
  const cartItems = useGetClientCartItemsByType(ProductType.Product)
  const isAuthenticated = useIsLoggedIn()
  const isClientSide = useIsClientSide()
  const sortedList = cartItems.sort(sortAlcoholOnTop).sort(sortMissingOnTop)
  const availabilitiesById = useProductAvailabilities(sortedList)
  const availabilityDate = useDeliveryDate()
  const getCurrentProductAvailabilityLabel = (id: string) =>
    getProductAvailabilityForDate(availabilitiesById?.[id], availabilityDate)?.label
  const innerRef = useRef<HTMLDivElement>(null)

  if (!isClientSide) {
    return null
  }

  if (!cartItems || !cartItems.length) {
    return isAuthenticated ? (
      <TrackingContextProvider
        trackingContext={TrackingContext.COPY_PREVIOUS_ORDER}
        listName={'copy-previous-order'}
      >
        <CartOrderHistory />
      </TrackingContextProvider>
    ) : (
      <CartIsEmpty />
    )
  }

  return (
    <div className={className} data-test-id="shoppingCart" ref={innerRef}>
      <CartHeaderContainer cartItems={cartItems} />
      <CartAlertsContainer cartItems={cartItems} />
      <ul>
        {sortedList.map((cartItem, index) => (
          <li key={cartItem.id}>
            <TrackingContextProvider
              listPosition={index}
              availabilityLabel={getCurrentProductAvailabilityLabel(cartItem.id)}
              trackingContext={TrackingContext.SHOPPING_CART}
              listName="side-cart"
            >
              <CartItem cartItem={cartItem} availabilities={availabilitiesById?.[cartItem.id]} />
            </TrackingContextProvider>
          </li>
        ))}
      </ul>
      {featureFlags.shoppingLists && isAuthenticated ? <CreateListFromCart /> : null}
    </div>
  )
}

_ShoppingCart.displayName = 'ShoppingCart'

export const ShoppingCart = styled(_ShoppingCart)({
  display: 'flex',
  flexDirection: 'column',
})
