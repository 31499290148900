import type { FC } from 'react'

import { StyledSyncLoader } from 'components/common/StyledSyncLoader'
import styled from 'styled-components'

const _MainLoading: FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <StyledSyncLoader />
  </div>
)

/**
 * A component used to display the loading status of the main content
 * in `BaseLayout`. This will render a tall empty space with a
 * centered loading indicator.
 */
export const MainLoading = styled(_MainLoading)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  /** global nav + local nav + just a bit to show the footer */
  minHeight: `calc(100vh - ${60 + 64 + 128}px)`,

  [`@media (min-width: calc(${theme.breakpoints.tablet} + 1px))`]: {
    /** global nav + local nav + just a bit to show the footer */
    minHeight: `calc(100vh - ${60 + 80 + 128}px)`,
  },
}))
