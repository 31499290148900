import type { FC, ReactNode } from 'react'

import { CollapsibleSidepanel } from 'components/CollapsibleSidepanel/CollapsibleSidepanel'
import { TopNavigationContainer } from 'components/Header/TopNavigation/TopNavigationContainer'
import { useContentfulContent } from 'services/Contentful/contentful-provider'
import { NotificationArea } from 'services/Notifications/NotificationArea'
import { OrderModificationNotificationContainer } from 'views/order/OrderModification/OrderModificationNotificationContainer'

import { BaseLayout } from './BaseLayout'
import { CartContainer } from './CartContainer'

export const CartSidebarLayout: FC<{
  children?: ReactNode
  'data-test-id'?: string
  hideMainNavigation?: boolean
  showFooter?: boolean
}> = ({ children, 'data-test-id': dataTestId, hideMainNavigation, showFooter }) => {
  const { serviceBanners: serviceBannerInUse } = useContentfulContent()

  return (
    <BaseLayout
      headerContent={hideMainNavigation ? null : <TopNavigationContainer />}
      serviceBannerInUse={!!serviceBannerInUse}
      mainContent={
        <div data-test-id={dataTestId}>
          <OrderModificationNotificationContainer />
          <NotificationArea group="global" />
          {children}
        </div>
      }
      sidebarContent={
        hideMainNavigation ? null : (
          <CollapsibleSidepanel>
            <CartContainer serviceBannerInUse={!!serviceBannerInUse} />
          </CollapsibleSidepanel>
        )
      }
      showFooter={showFooter}
    />
  )
}
