import type { FC, ReactNode } from 'react'

import { useEffect } from 'react'
import styled from 'styled-components'

import { useCollapsibleSidepanelContext } from './CollapsibleSidepanelProvider'

const Sidepanel = styled.div({
  flexGrow: 1,
  display: 'flex',

  '> *': {
    width: 400,
  },

  '#hide-sidepanel:checked ~ * &.hideable': {
    display: 'none',
  },
})

export const CollapsibleSidepanel: FC<{ children: ReactNode }> = ({ children }) => {
  const { isSidepanelVisible, setIsSidepanelCollapsible } = useCollapsibleSidepanelContext()

  useEffect(() => {
    setIsSidepanelCollapsible(true)
    return () => setIsSidepanelCollapsible(false)
  }, [setIsSidepanelCollapsible])

  // value is null during ssr and component is rendered but hidden by css
  if (isSidepanelVisible === null) {
    return (
      <Sidepanel id="sidepanel" className="hideable">
        {children}
      </Sidepanel>
    )
  }

  return isSidepanelVisible ? (
    <Sidepanel id="sidepanel" key="sidebar">
      {children}
    </Sidepanel>
  ) : null
}
