import type { ClientCartItem, ProductAvailabilities } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { DeleteOutline24 } from '@sok/design-system'
import { ProductCard } from 'components/Product'
import { CommentBtn, DeleteBtn } from 'components/Product/ExtraActions'
import { ExtraActionsContainer } from 'components/Product/ProductCard'
import { ProductComment, ProductCommentModal } from 'components/Product/ProductCommentModal'
import { RemoveCartItem } from 'components/Product/RemoveCartItem'
import { ReplaceCheckBox } from 'components/Product/ReplaceCheckBox'
import { ProductAvailabilityInfo } from 'components/ProductAvailabilityInfo'
import { ProductLabels } from 'components/ProductLabels'
import { equals } from 'ramda'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetAdditionalInfo } from 'services/ClientCart/mutations/set-additional-info'
import { useToggleReplace } from 'services/ClientCart/mutations/toggle-replace'
import { useRemoveCartItems } from 'services/ClientCart/mutations/use-remove-cart-items'
import { ModalService } from 'services/Modal'
import { mapCartItemToProduct } from 'utils/cart/partition-cart-items'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { commonLogger } from 'utils/log/common-logger'
import {
  trackCartCommentSave,
  trackCartRemoveItem,
  trackCartReplacementToggle,
} from 'utils/tracking/cart'
import { useTrackingContext } from 'utils/tracking/components/TrackingContextProvider'
import { GA4_trackProductClick } from 'utils/tracking/track-product-click'

import { AddToCart } from './AddToCart'

const { domain } = getConfig()

interface CartItemProps {
  cartItem: ClientCartItem
  availabilities: ProductAvailabilities[] | null
}

// Fix re-rendering of all cart items when a single cart item is changed.
export const CartItem: FC<CartItemProps> = memo(
  (props) => {
    return <_CartItem {...props} />
  },
  (prev, next) => equals(prev, next),
)

CartItem.displayName = 'CartItem'

const _CartItem: FC<CartItemProps> = ({ cartItem, availabilities }) => {
  const selectedStoreId = useStoreId().selectedStoreId
  const productData = mapCartItemToProduct(cartItem, selectedStoreId)
  const trackingContext = useTrackingContext()
  const toggleReplace = useToggleReplace()
  const setAdditionalInfo = useSetAdditionalInfo()

  const { t } = useTranslation()
  const cartItemDisabled = !cartItem.inStoreSelection

  const removeCartItems = useRemoveCartItems()

  const onRemove = async () => {
    trackCartRemoveItem('side-cart')
    await removeCartItems([{ id: cartItem.id }])
  }

  const onSubmit = async (additionalInfo: string) => {
    trackCartCommentSave('side-cart')
    try {
      await setAdditionalInfo(cartItem.id, additionalInfo)
    } catch (err) {
      commonLogger.errorSync({
        error: err,
        message: `Setting comment for ${cartItem.id} wasn't successful`,
      })
    }
  }

  const showCommentModal = () =>
    ModalService.open<string>(
      <ProductCommentModal value={cartItem.additionalInfo || ''} data={productData} />,
      { onSubmit },
    )

  const onReplace = async () => {
    trackCartReplacementToggle('side-cart', !cartItem.replace)

    await toggleReplace(cartItem.id)
  }

  const onProductClick = () =>
    GA4_trackProductClick({
      ...trackingContext,
      products: [
        {
          ...cartItem,
          isReplaceable: cartItem.replace,
        },
      ],
    })

  const { isSIdEnabled } = domain

  return (
    <ProductCard.SideCart.Success
      status={<ProductAvailabilityInfo availabilities={availabilities} />}
      disabled={cartItemDisabled}
      data={productData}
      handleClick={onProductClick}
      data-product-id={cartItem.id}
      data-test-id="cartItem"
    >
      {cartItemDisabled ? (
        <RemoveCartItem icon={<DeleteOutline24 />} text={t('Remove')} onClick={onRemove} />
      ) : (
        <>
          <ProductLabels
            data-test-id="cartItem__productLabels"
            product={{
              isAgeLimitedByAlcohol: productData.isAgeLimitedByAlcohol,
              packagingLabelCodes: productData.packagingLabelCodes,
              frozen: productData.frozen,
            }}
            size={16}
            truncate
          />
          <AddToCart product={cartItem} />
          <ExtraActionsContainer>
            {isSIdEnabled ? (
              <ReplaceCheckBox checked={!!cartItem.replace} onChange={onReplace}>
                {t('Replace')}
              </ReplaceCheckBox>
            ) : (
              <div />
            )}
            <div>
              {isSIdEnabled && (
                <CommentBtn
                  title={t('Add comment')}
                  data-test-id="add-comment-btn"
                  isFilled={!!cartItem.additionalInfo}
                  onClick={showCommentModal}
                />
              )}
              <DeleteBtn onClick={onRemove} data-test-id="remove-cart-item-btn" />
            </div>
          </ExtraActionsContainer>
          {cartItem.additionalInfo && (
            <ProductComment data-test-id="cart-item-comment">
              {cartItem.additionalInfo}
            </ProductComment>
          )}
        </>
      )}
    </ProductCard.SideCart.Success>
  )
}
