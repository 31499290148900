import type { ApolloStateProp } from 'lib/apollo/get-apollo-client'
import type { GetServerSideProps } from 'next'
import type { FC } from 'react'
import type { ContentfulStateProp } from 'services/Contentful/get-contentful-content'

import { getConfig } from '@shared/config'
import { GetSelectedStoreIdDocument, RemoteStoreCoopInfoDocument } from '@shared/gql/document-nodes'
import { minWidthFromTheme } from '@sok/design-system'
import { CartSidebarLayout } from 'components/layout/CartSidebarLayout'
import { MainLoading } from 'components/layout/MainLoading'
import { getServerSideApolloClient } from 'lib/apollo/get-apollo-client'
import { getSelectedBrandFromApollo } from 'lib/apollo/get-selected-brand-from-apollo'
import { NextSeo } from 'next-seo'
import { useEffect, useRef, useState } from 'react'
import { useContentfulContent } from 'services/Contentful/contentful-provider'
import { getContentfulContent } from 'services/Contentful/get-contentful-content'
import { NotificationArea } from 'services/Notifications/NotificationArea'
import styled from 'styled-components'
import { useCanonicalUrl } from 'utils/hooks/use-canonical-url'
import { useScrollCallback } from 'utils/hooks/use-scroll-callback'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

import { PageLayoutSections } from './components/PageLayoutSections'

const { domain } = getConfig()

const StyledContainer = styled.div(({ theme }) => ({
  /** When there are notification banners on top of this container, add some margin */
  [minWidthFromTheme(theme).desktop]: {
    '&:not(:first-child)': {
      marginTop: theme.spacings.medium,
    },
  },
}))

export const LandingPage: FC = () => {
  const { frontPage } = useContentfulContent()
  const seoTitle = frontPage?.seoTitle
  const seoDescription = frontPage?.seoDescription
  const seoCanonical = useCanonicalUrl()

  const [scrollStage, setScrollStage] = useState(0)
  const homePageContentRef = useRef<HTMLDivElement>(null)

  useEffect((): void => {
    if (scrollStage > 0) {
      trackCustomEvent({
        category: EventCategory.SCROLLING,
        action: EventAction.SCROLL,
        label: `home-page-container_${scrollStage}/4`,
      })
    }
  }, [scrollStage])

  useScrollCallback(
    (currentPosition) => {
      // Tracking side effect below
      const max = homePageContentRef.current?.scrollHeight || 0
      const currentOffset = currentPosition + (homePageContentRef.current?.clientHeight || 0)
      const fourth: number = Math.floor(max / 4)
      const half: number = 2 * fourth
      const threeFourths: number = 3 * fourth

      if (currentOffset > max * 0.9 && scrollStage < 4) {
        setScrollStage(4)
      } else if (currentOffset > threeFourths && scrollStage < 3) {
        setScrollStage(3)
      } else if (currentOffset > half && scrollStage < 2) {
        setScrollStage(2)
      } else if (currentOffset > fourth && scrollStage < 1) {
        setScrollStage(1)
      }
    },
    homePageContentRef,
    [],
  )

  if (!frontPage) {
    return (
      <CartSidebarLayout data-test-id="page__landing">
        <NextSeo title={seoTitle} description={seoDescription} canonical={seoCanonical} />
        <MainLoading />
      </CartSidebarLayout>
    )
  }

  return (
    <CartSidebarLayout data-test-id="page__landing">
      <NextSeo title={seoTitle} description={seoDescription} canonical={seoCanonical} />
      <NotificationArea group={'new-feature-announcement'} />

      <div ref={homePageContentRef}>
        <StyledContainer data-test-id="frontPageSection">
          <PageLayoutSections sections={frontPage?.sections} />
        </StyledContainer>
      </div>
    </CartSidebarLayout>
  )
}

export const getServerSideProps: GetServerSideProps<ApolloStateProp & ContentfulStateProp> = async (
  context,
) => {
  const apolloClient = await getServerSideApolloClient(context)
  const brand = getSelectedBrandFromApollo(apolloClient)

  const storeId =
    apolloClient.cache.readQuery({
      query: GetSelectedStoreIdDocument,
    })?.selectedStoreId || domain.defaultStoreId

  const [contentfulState] = await Promise.all([
    getContentfulContent(brand),
    /** Needed for Contentful component supporting co-op variations */
    apolloClient.query({
      query: RemoteStoreCoopInfoDocument,
      variables: { id: storeId },
    }),
  ])

  return {
    props: {
      apolloState: apolloClient.cache.extract(),
      contentfulState,
    },
  }
}
