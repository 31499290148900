import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { CheckoutButton } from 'components/CheckoutButton'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ShoppingCart } from '../ShoppingCart/ShoppingCart'

interface CartContainerProps {
  className?: string
  serviceBannerInUse?: boolean
}

const { domain } = getConfig()

const _CartContainer: FC<CartContainerProps> = ({ className }) => {
  const { t } = useTranslation()

  const { isSIdEnabled } = domain

  return (
    <div className={className} data-test-id="cart-container">
      <ShoppingCart />

      {isSIdEnabled && (
        <CheckoutButton href="/yhteenveto" showPrice data-test-id="continue-order-button">
          {t('To forward')}
        </CheckoutButton>
      )}
    </div>
  )
}

_CartContainer.displayName = 'CartContainer'

export const CartContainer = styled(_CartContainer)(
  {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 400,

    [ShoppingCart]: {
      flexGrow: 1,
    },
  },
  ({ theme, serviceBannerInUse }) => ({
    [CheckoutButton]: {
      backgroundColor: theme.color.white,
      bottom: 0,
      marginTop: 'auto',
      padding: theme.spacings.xxSmall,
      width: serviceBannerInUse ? '400px' : 'inherit',
      position: serviceBannerInUse ? 'fixed' : 'sticky',
    },
  }),
)
