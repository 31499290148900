import type { DependencyList, RefObject } from 'react'

import { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useScrollCallback = (
  callback: (scrollPosition: number) => void,
  refEl: RefObject<HTMLElement>,
  deps: DependencyList,
): void => {
  const debouncedOnScroll = useDebouncedCallback(({ target }) => {
    callback(parseInt(target.scrollTop))
  }, 80)

  const dependencyList: DependencyList = [refEl, debouncedOnScroll, ...deps]

  useEffect(() => {
    const element = refEl.current

    if (element !== null) {
      element.addEventListener('scroll', debouncedOnScroll)
    }
    return () => {
      if (element !== null) {
        element.removeEventListener('scroll', debouncedOnScroll)
      }
    }
    // Cannot be statically checked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
